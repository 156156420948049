import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What's the Goal?`}</h2>
    <h3>{`Tools that consume or run JavaScript modules...`}</h3>
    <ul>
      <li parentName="ul">{`Don't allow synchronous access to loading JavaScript modules.`}</li>
      <li parentName="ul">{`Don't include CommonJS arguments inside of JavaScript modules.`}</li>
      <li parentName="ul">{`Can handle files with the extension `}<inlineCode parentName="li">{`.mjs`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Always treat `}<inlineCode parentName="li">{`.mjs`}</inlineCode>{` files as JavaScript modules.`}</li>
    </ul>
    <h3>{`Tools that consume or run CommonJS scripts...`}</h3>
    <ul>
      <li parentName="ul">{`Offer comparable support for JavaScript modules.`}</li>
      <li parentName="ul">{`Don't allow `}<inlineCode parentName="li">{`require`}</inlineCode>{` of JavaScript modules.`}</li>
      <li parentName="ul">{`Can handle files with the extension `}<inlineCode parentName="li">{`.cjs`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Always treat `}<inlineCode parentName="li">{`.cjs`}</inlineCode>{` files as JavaScript modules.`}</li>
    </ul>
    <h3>{`Tools that produce JavaScript modules...`}</h3>
    <ul>
      <li parentName="ul">{`Can generate files with the extension `}<inlineCode parentName="li">{`.mjs`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Write only contents that follow module semantics into `}<inlineCode parentName="li">{`.mjs`}</inlineCode>{` files.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      